export enum SiteType {
  HALO_OGLASI = "halo_oglasi",
  NEKRETNINE = "nekretnine",
  NADJI_DOM = "nadji_dom",
  CETIRI_ZIDA = "4zida",
  CETIRI_ZIDA_API_V6 = "4zida_API_V6",
  SASOMANGE = "sasomange",
}

export type Site = {
  type: SiteType;
  url: string;
};

export function createSite(overrides: Partial<Site> = {}): Site {
  return {
    type: SiteType.HALO_OGLASI,
    url: "",
    ...overrides,
  };
}
