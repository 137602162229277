import React, { useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

const AppToolbarContext = React.createContext<{
  setActionButtons: (actionButtons: JSX.Element | null) => void;
  actionButtons: JSX.Element | null;
}>({
  actionButtons: null,
  setActionButtons: () => {},
});

export const useAppToolbar = () => useContext(AppToolbarContext);

export const AppToolbarProvider: React.FC = ({ children }) => {
  const location = useLocation();
  const [actionButtons, setActionButtons] = useState<JSX.Element | null>(null);
  useEffect(() => {
    setActionButtons(null);
  }, [location]);
  return (
    <AppToolbarContext.Provider value={{ actionButtons, setActionButtons }}>
      {children}
    </AppToolbarContext.Provider>
  );
};
