import {
  CollectionReference,
  getDocs,
  limit,
  orderBy,
  query,
  QueryDocumentSnapshot,
  startAfter,
} from "firebase/firestore";
import { useCallback, useEffect, useState } from "react";

export function usePaginatedCollection<T>(
  collectionRef: CollectionReference<T>,
  orderByFieldPath: string,
  pageSize = 25
) {
  const [hasMore, setHasMore] = useState(false);
  const [data, setData] = useState<T[]>([]);
  const [loading, setLoading] = useState(true);
  const [loadingMore, setLoadingMore] = useState(false);
  const [last, setLast] = useState<QueryDocumentSnapshot<T> | null>(null);

  useEffect(() => {
    async function fetchInitial() {
      setData([]);
      const first = query(
        collectionRef,
        orderBy(orderByFieldPath, "desc"),
        limit(pageSize)
      );
      const snapshots = await getDocs(first);
      setData(snapshots.docs.map((d) => d.data()));
      setLoading(false);
      const lastVisible = snapshots.docs[snapshots.docs.length - 1];
      setLast(lastVisible);
      setHasMore(snapshots.docs.length === pageSize);
    }

    fetchInitial();
  }, [collectionRef, orderByFieldPath, pageSize]);

  const fetchMore = useCallback(async () => {
    setLoadingMore(true);
    const next = query(
      collectionRef,
      orderBy(orderByFieldPath, "desc"),
      startAfter(last),
      limit(pageSize)
    );

    const snapshots = await getDocs(next);
    const moreData = snapshots.docs.map((d) => d.data());
    setData((d) => d.concat(moreData));

    setLast(snapshots.docs[snapshots.docs.length - 1]);
    setHasMore(snapshots.docs.length === pageSize);
    setLoadingMore(false);
  }, [collectionRef, last, orderByFieldPath, pageSize]);

  return { data, loading, fetchMore, hasMore, loadingMore };
}
