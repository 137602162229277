import { BaseModel, createFirebaseApi } from "src/api/api-factory";

export class UserProfile implements BaseModel {
  id: string = "";
  admin: boolean = false;

  fromPartial(data: Partial<this>): this {
    this.id = data.id ?? this.id;
    this.admin = data.admin ?? this.admin;

    return this;
  }
}

export const { getDocRef: getUserProfileRef } = createFirebaseApi({
  path: "users",
  ctor: UserProfile,
});
