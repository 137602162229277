import React from "react";
import { Outlet, Route, Routes } from "react-router-dom";
import { AdvertsPage } from "src/pages/AdvertsPage";
import { FlowEditPage } from "src/pages/FlowEditPage";
import { FlowsCreatePage } from "src/pages/FlowsCreatePage";
import { SettingsPage } from "src/pages/SettingsPage";
import "./App.css";
import { RequireAuth } from "./auth/RequireAuth";
import { ProfilePage } from "./pages/ProfilePage";

const SignInPage = React.lazy(() => import("./pages/SignInPage"));
const MainPage = React.lazy(() => import("./pages/MainPage"));

function App() {
  return (
    <Routes>
      <Route path="/sign-in" element={<SignInPage />} />
      <Route
        path="/"
        element={
          <RequireAuth>
            <Outlet />
          </RequireAuth>
        }
      >
        <Route element={<MainPage />}>
          <Route index element={<AdvertsPage />} />
          <Route path="settings" element={<SettingsPage />} />
          <Route path="profile" element={<ProfilePage />} />
          <Route path="settings/new" element={<FlowsCreatePage />} />
          <Route path="settings/:flowId" element={<FlowEditPage />} />
        </Route>
      </Route>
    </Routes>
  );
}

export default App;
