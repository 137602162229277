import { MenuItem, Select } from "@mui/material";
import { SelectChangeEvent } from "@mui/material/Select/SelectInput";
import * as React from "react";
import { useEffect, useState } from "react";
import { Flow, getFlowsCollectionRef } from "src/api/flow";
import { useCollectionSubscription } from "src/hooks/useCollectionSubscription";

type FlowSelectorProps = {
  onChange?: (flow: Flow | null) => void;
  initialFlowId?: string;
};

export const FlowSelector: React.FC<FlowSelectorProps> = ({
  initialFlowId,
  onChange,
}) => {
  const { data: flows, loading } = useCollectionSubscription(
    getFlowsCollectionRef
  );

  const [selectedFlow, setSelectedFlow] = useState<Flow | null>(null);

  useEffect(() => {
    if (!loading) {
      const flow =
        flows.find((f) => f.id === selectedFlow?.id ?? initialFlowId) ??
        flows[0];

      setSelectedFlow(flow);
    }
  }, [loading, initialFlowId, flows, selectedFlow?.id]);

  useEffect(() => {
    onChange?.(selectedFlow);
  }, [selectedFlow, onChange]);

  const handleChange = (event: SelectChangeEvent<string>) => {
    const flowId = event.target.value;
    const flow = flows.find((f) => f.id === flowId)!;
    onChange?.(flow);
    setSelectedFlow(flow);
  };

  return (
    <Select value={selectedFlow?.id ?? ""} onChange={handleChange}>
      {flows?.map((flow) => (
        <MenuItem key={flow.id} value={flow.id}>
          {flow.name}
        </MenuItem>
      ))}
    </Select>
  );
};
