import * as React from "react";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getFlowDocRef } from "src/api/flow";
import { FlowForm, FlowFormValues } from "src/components/CreateFlow/FlowForm";
import { Loader } from "src/components/Loader";
import { useDocSubscription } from "src/hooks/useDocSubscription";

type FlowEditPageProps = {};

export const FlowEditPage: React.FC<FlowEditPageProps> = () => {
  const { flowId } = useParams();
  const [initialValues, setInitialValues] = useState<FlowFormValues | null>(
    null
  );
  const { data: flow, loading } = useDocSubscription(getFlowDocRef, flowId!);

  useEffect(() => {
    if (!flow) {
      return;
    }

    setInitialValues({ flow });
  }, [flow]);

  if (loading || !initialValues) {
    return <Loader />;
  }

  return <FlowForm initialValues={initialValues} />;
};
