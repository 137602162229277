import {
  addDoc,
  collection,
  CollectionReference,
  deleteDoc,
  doc,
  DocumentReference,
  FirestoreDataConverter,
  getDoc,
  setDoc,
} from "firebase/firestore";
import { db } from "src/firebase";

export interface BaseModel {
  id: string;
  fromPartial: (data: Partial<this>) => this;
  toString: () => string;
}

export type CreateFirebaseApiOpts<Model extends BaseModel> = {
  path: string;
  ctor: { new (): Model };
};

export function createConverter<Model extends BaseModel>(ctor: {
  new (): Model;
}): FirestoreDataConverter<Model> {
  return {
    fromFirestore(snapshot, options?): Model {
      const data = snapshot.data(options);

      return new ctor().fromPartial({
        id: snapshot.id,
        ...data,
      } as Partial<Model>);
    },
    toFirestore({ id, ...data }: Model) {
      return data;
    },
  };
}

export function createFirebaseApi<Model extends BaseModel>(
  opts: CreateFirebaseApiOpts<Model>
) {
  const converter = createConverter(opts.ctor);

  const getCollectionRef = (): CollectionReference<Model> =>
    collection(db, opts.path).withConverter(converter);

  const getDocRef = (id: string): DocumentReference<Model> =>
    doc(db, opts.path, id).withConverter(converter);

  const create = (data: Partial<Model>) =>
    addDoc(getCollectionRef(), new opts.ctor().fromPartial(data));

  const get = (id: string) => getDoc(getDocRef(id));

  const update = (data: Partial<Model> & { id: Model["id"] }) =>
    setDoc(getDocRef(data.id), new opts.ctor().fromPartial(data));

  const deleteFn = (id: string) => deleteDoc(getDocRef(id));

  return {
    converter,
    getCollectionRef,
    getDocRef,
    create,
    get,
    update,
    delete: deleteFn,
  };
}
