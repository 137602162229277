import { Grid, Paper, Switch, Typography } from "@mui/material";
import * as React from "react";
import { useAdmin } from "src/context/admin-context";

type AdminPanelProps = {};

export const AdminPanel: React.FC<AdminPanelProps> = () => {
  const { superUser, setSuperUser } = useAdmin();

  return (
    <Paper variant={"outlined"} sx={{ p: 2 }}>
      <Typography gutterBottom variant={"h6"}>
        Admin panel
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={4}>
          <Typography>Act as SuperUser</Typography>
        </Grid>
        <Grid item xs={8}>
          <Switch
            checked={superUser}
            onChange={(e, checked) => setSuperUser(checked)}
          />
        </Grid>
      </Grid>
    </Paper>
  );
};
