import { Stack } from "@mui/material";
import { where } from "firebase/firestore";
import * as React from "react";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Flow, getFlowsCollectionRef } from "src/api/flow";
import { useAuth } from "src/auth/auth";
import { AdvertsGrid } from "src/components/AdvertsGrid";
import { EmptyView } from "src/components/EmptyView";
import { FlowSelector } from "src/components/FlowSelector";
import { Loader } from "src/components/Loader";
import { useAdmin } from "src/context/admin-context";
import { useCollectionSubscription } from "src/hooks/useCollectionSubscription";

type AdvertsPageProps = {};

export const AdvertsPage: React.FC<AdvertsPageProps> = () => {
  const { superUser } = useAdmin();
  const { user } = useAuth();
  const navigate = useNavigate();
  const [selectedFlow, setSelectedFlow] = useState<Flow | null>(null);

  const queryConstraints = superUser
    ? []
    : [where("owners", "array-contains", user?.uid)];

  const { data: flows, loading } = useCollectionSubscription(
    getFlowsCollectionRef,
    queryConstraints
  );

  useEffect(() => {
    if (flows) {
      setSelectedFlow(flows[0]);
    }
  }, [flows]);

  const handleCreateFlow = () => {
    navigate("/settings/new");
  };

  if (loading) {
    return <Loader />;
  }

  return (
    <Stack spacing={2} py={2}>
      {flows.length > 1 && <FlowSelector onChange={setSelectedFlow} />}

      {selectedFlow && <AdvertsGrid flow={selectedFlow} />}

      {flows.length === 0 && (
        <EmptyView
          text="Nema podešene konfiguracije."
          onCreateBtnClick={handleCreateFlow}
        />
      )}
    </Stack>
  );
};
