import { Unsubscribe } from "@firebase/firestore";
import {
  GoogleAuthProvider,
  onAuthStateChanged,
  signInWithPopup,
  signOut as firebaseSignOut,
  User,
  UserCredential,
} from "firebase/auth";
import { onSnapshot } from "firebase/firestore";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { getUserProfileRef, UserProfile } from "src/auth/user-profile";
import { auth } from "../firebase";

const googleProvider = new GoogleAuthProvider();

const AuthContext = React.createContext<{
  user: User | null;
  userProfile: UserProfile | null;
  signIn: () => Promise<UserCredential | undefined>;
  signOut: () => void;
}>({
  user: null,
  userProfile: null,
  signIn: () => Promise.resolve(undefined),
  signOut: () => {},
});

export const AuthProvider: React.FC = ({ children }) => {
  const [userInitialized, setUserInitialized] = useState(false);
  const [userProfileInitialized, setUserProfileInitialized] = useState(false);
  const [user, setUser] = useState<User | null>(null);
  const [userProfile, setUserProfile] = useState<UserProfile | null>(null);

  useEffect(() => {
    let unsubscribe: Unsubscribe;
    onAuthStateChanged(
      auth,
      (u) => {
        if (u) {
          setUser(u);
          unsubscribe?.();
          const ref = getUserProfileRef(u.uid);
          unsubscribe = onSnapshot(ref, (docSnapshot) => {
            if (!docSnapshot.exists()) {
              setUserProfile(null);
              console.error(`UserProfile[${docSnapshot.id}] does NOT exist`);
            } else {
              setUserProfile(docSnapshot.data());
            }
            setUserProfileInitialized(true);
          });
        } else {
          setUser(null);
          setUserProfileInitialized(true);
        }
        setUserInitialized(true);
      },
      (error) => {
        console.log(error);
      }
    );

    return () => {
      unsubscribe?.();
    };
  }, []);

  const signIn = useCallback(async () => {
    try {
      return await signInWithPopup(auth, googleProvider);
    } catch (err) {
      console.error(err);
    }
  }, []);

  const signOut = useCallback(() => firebaseSignOut(auth), []);

  if (!userInitialized || !userProfileInitialized) {
    return null;
  }

  return (
    <AuthContext.Provider value={{ user, signIn, signOut, userProfile }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);
