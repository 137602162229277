import { Avatar, Button, Stack, Typography } from "@mui/material";
import * as React from "react";
import { useAuth } from "src/auth/auth";
import { AdminPanel } from "src/components/AdminPanel";
import { useAdmin } from "src/context/admin-context";

type ProfilePageProps = {};

export const ProfilePage: React.FC<ProfilePageProps> = () => {
  const { user, signOut } = useAuth();
  const { admin } = useAdmin();
  return (
    <>
      <Stack spacing={2} alignItems={"center"} p={5}>
        <Avatar
          src={user?.photoURL ?? undefined}
          variant={"circular"}
          sx={{ width: 128, height: 128 }}
        />
        <Typography>{user?.displayName}</Typography>
        <Button onClick={signOut} variant={"contained"}>
          Sign Out
        </Button>
      </Stack>
      {admin && <AdminPanel />}
    </>
  );
};
