import React, { useContext, useEffect, useState } from "react";
import { useAuth } from "src/auth/auth";

type AdminContextValue = {
  admin: boolean;
  superUser: boolean;
  setSuperUser: (value: boolean) => void;
};

const AdminContext = React.createContext<AdminContextValue>({
  admin: false,
  superUser: false,
  setSuperUser: () => {},
});

export const AdminProvider: React.FC = ({ children }) => {
  const { userProfile } = useAuth();

  const [superUser, setSuperUser] = useState(false);
  useEffect(() => {
    const dataFromStorage = localStorage.getItem("super_user");
    setSuperUser(dataFromStorage === "true");
  }, []);

  const handleSuperUserChange = (value: boolean) => {
    const v = value ? "true" : "false";
    localStorage.setItem("super_user", v);
    setSuperUser(value);
  };

  return (
    <AdminContext.Provider
      value={{
        admin: Boolean(userProfile?.admin),
        superUser: superUser && Boolean(userProfile?.admin),
        setSuperUser: handleSuperUserChange,
      }}
    >
      {children}
    </AdminContext.Provider>
  );
};

export const useAdmin = () => useContext(AdminContext);
