import CachedIcon from "@mui/icons-material/Cached";
import { Box, Fab, Grid, Stack, Zoom } from "@mui/material";
import * as React from "react";
import { useMemo } from "react";
import { getAdvertsCollectionRef } from "src/api/advert";
import { Flow } from "src/api/flow";
import { AdvertCard } from "src/components/AdvertCard";
import { EmptyView } from "src/components/EmptyView";
import { Loader } from "src/components/Loader";
import { usePaginatedCollection } from "src/hooks/usePaginatedCollection";

type AdvertsGridProps = {
  flow: Flow;
};

export const AdvertsGrid: React.FC<AdvertsGridProps> = ({ flow }) => {
  const collectionRef = useMemo(() => {
    return getAdvertsCollectionRef(flow.id);
  }, [flow]);

  const {
    data: adverts,
    loading,
    fetchMore,
    hasMore,
    loadingMore,
  } = usePaginatedCollection(collectionRef, "scrapedAt");

  if (loading) {
    return <Loader />;
  }

  if (adverts.length === 0) {
    return (
      <EmptyView text="Ovde će biti prikazani oglasi kada sledeći put sistem izvrši pretragu." />
    );
  }

  return (
    <Stack spacing={2}>
      <Grid container spacing={2}>
        {adverts?.map((ad) => (
          <Grid key={ad.id} item md={4} sm={6} xs={12}>
            <AdvertCard advert={ad} />
          </Grid>
        ))}
      </Grid>
      <Box sx={{ alignSelf: "center" }}>
        <Zoom in={hasMore} unmountOnExit>
          <Fab color={"primary"} onClick={fetchMore} disabled={loadingMore}>
            <CachedIcon />
          </Fab>
        </Zoom>
      </Box>
    </Stack>
  );
};
