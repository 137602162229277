import { Box, Card, CardContent, CardMedia, Typography } from "@mui/material";
import * as React from "react";
import { Advert } from "src/api/advert";

type AdvertCardProps = {
  advert: Advert;
};

const formatter = new Intl.NumberFormat(undefined, {
  style: "currency",
  currency: "EUR",

  // These options are needed to round to whole numbers if that's what you want.
  //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
  maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
});

export const AdvertCard: React.FC<AdvertCardProps> = ({ advert }) => {
  const openAdvert = () => {
    window.open(advert.url, "_blank");
  };

  return (
    <Card
      variant="outlined"
      sx={{ height: "100%", display: "flex", flexDirection: "column" }}
      onClick={openAdvert}
    >
      <CardMedia
        component="img"
        height="140"
        image={advert.img}
        alt={advert.title}
      />
      <CardContent
        sx={{ flexGrow: 1, display: "flex", flexDirection: "column" }}
      >
        <Box sx={{ flexGrow: 1 }}>
          <Typography gutterBottom variant="h6" component="div">
            {advert.title}
          </Typography>
        </Box>
        <Box>
          <Typography variant="body2" color="text.secondary">
            {formatter.format(advert.price)}
          </Typography>
          <Typography variant="body2">
            <small>{advert.scrapedAt.toLocaleString()}</small>
          </Typography>
        </Box>
      </CardContent>
    </Card>
  );
};
