import { getAuth } from "firebase/auth";
import { collection, getDoc, getDocs, query } from "firebase/firestore";
import { Advert, converter as advertConverter } from "src/api/advert";
import { BaseModel, createFirebaseApi } from "src/api/api-factory";
import { Site } from "src/api/site";
import { db } from "src/firebase";

const FIRESTORE_COLLECTION_PATH = "flows";

export type MailNotification = {
  to: string[];
  subject: string;
};

export class Flow implements BaseModel {
  id: string = "";
  name: string = "";
  owners: string[] = [];
  mailNotification: MailNotification = {
    to: [],
    subject: "",
  };
  sites: Site[] = [];
  active: boolean = true;

  fromPartial(data: Partial<this>) {
    this.id = data.id ?? this.id;
    this.name = data.name ?? this.name;
    this.owners = data.owners ?? this.owners;
    this.mailNotification = data.mailNotification ?? this.mailNotification;
    this.sites = data.sites ?? this.sites;
    this.active = data.active ?? this.active;
    return this;
  }

  toString() {
    return `Flow[id=${this.id}, name=${this.name}]`;
  }

  async getAdverts(): Promise<Advert[]> {
    const docs = await getDocs(
      query(
        collection(
          db,
          `${FIRESTORE_COLLECTION_PATH}/${this.id}/adverts`
        ).withConverter(advertConverter)
      )
    );
    return docs.docs.map((d) => d.data());
  }
}

const {
  create,
  update,
  getDocRef,
  getCollectionRef,
  delete: deleteFlowFn,
} = createFirebaseApi({
  path: FIRESTORE_COLLECTION_PATH,
  ctor: Flow,
});

export const deleteFlow = deleteFlowFn;
export const getFlowsCollectionRef = getCollectionRef;
export const getFlowDocRef = getDocRef;

export const createFlow = async (data: Flow) => {
  const createdBy = getAuth().currentUser?.uid;
  if (!createdBy) {
    throw new Error("updateScraper: User not authorised");
  }

  const flowRef = create(
    new Flow().fromPartial({
      ...data,
      owners: [createdBy],
    })
  );

  return flowRef;
};

export const updateFlow = async (data: Flow) => {
  const updatedBy = getAuth().currentUser?.uid;
  if (!updatedBy) {
    throw new Error("updateFlow: User not authorised");
  }
  return update(new Flow().fromPartial(data));
};

export const getFlow = async (id: string) => getDoc(getDocRef(id));

export const runFlow = async (id: string) => {
  const uid = await getAuth().currentUser?.getIdToken();
  return fetch(`/api/flow-engine/run-flow/${id}`, {
    method: "POST",
    headers: {
      authorization: `Bearer ${uid}`,
    },
  });
};
