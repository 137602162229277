import { DocumentReference, onSnapshot } from "firebase/firestore";
import { useEffect, useState } from "react";

type UseDocSubscription<T> =
  | { loading: true; data: null }
  | { loading: false; data: T };

export const useDocSubscription = <T>(
  getDocRef: (id: string) => DocumentReference<T>,
  id: string
): UseDocSubscription<T> => {
  const [data, setData] = useState<T | null>(null);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    const ref = getDocRef(id);
    const unsubscribe = onSnapshot(ref, (docSnapshot) => {
      if (!docSnapshot.exists()) {
        setData(null);
        throw new Error(`doc[${docSnapshot.id}] does NOT exist`);
      }
      setData(docSnapshot.data());
      setLoading(false);
    });

    return () => {
      unsubscribe();
    };
  }, [getDocRef, id]);

  return { data, loading } as UseDocSubscription<T>;
};
