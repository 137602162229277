import { Add } from "@mui/icons-material";
import {
  Fab,
  List,
  Paper,
  Stack,
  Theme,
  useMediaQuery,
  useTheme,
  Zoom,
} from "@mui/material";
import { where } from "firebase/firestore";
import * as React from "react";
import { useNavigate } from "react-router-dom";
import { Flow, getFlowsCollectionRef, runFlow } from "src/api/flow";
import { useAuth } from "src/auth/auth";
import { EmptyView } from "src/components/EmptyView";
import { FlowListItem } from "src/components/FlowListItem";
import { Loader } from "src/components/Loader";
import { useAdmin } from "src/context/admin-context";
import { useCollectionSubscription } from "src/hooks/useCollectionSubscription";

type SettingsPageProps = {};

export const SettingsPage: React.FC<SettingsPageProps> = () => {
  const { user } = useAuth();
  const { superUser } = useAdmin();
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));

  const queryConstraints = superUser
    ? []
    : [where("owners", "array-contains", user?.uid)];

  const { data, loading } = useCollectionSubscription(
    getFlowsCollectionRef,
    queryConstraints
  );
  const navigate = useNavigate();

  const handleNew = () => {
    navigate("./new");
  };

  const handleFlowClick = (flow: Flow) => {
    navigate(`./${flow.id}`);
  };

  const handleFlowActionClick = async (flow: Flow) => {
    await runFlow(flow.id);
  };

  if (loading) {
    return <Loader />;
  }

  if (data.length === 0) {
    return <EmptyView onCreateBtnClick={handleNew} />;
  }

  return (
    <Paper variant={"outlined"}>
      <Stack spacing={2}>
        <List dense>
          {data.map((flow) => (
            <FlowListItem
              key={flow.id}
              flow={flow}
              onClick={() => handleFlowClick(flow)}
              onActionClick={() => handleFlowActionClick(flow)}
            />
          ))}
        </List>

        <Zoom in={true} unmountOnExit>
          <Fab
            color="primary"
            onClick={handleNew}
            sx={{
              position: isDesktop ? "absolute" : "fixed",
              bottom: (theme: Theme) =>
                isDesktop ? theme.spacing(2) : theme.spacing(9),
              right: (theme) => theme.spacing(2),
            }}
          >
            <Add />
          </Fab>
        </Zoom>
      </Stack>
    </Paper>
  );
};
