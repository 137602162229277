import { CssBaseline, ThemeProvider } from "@mui/material";
import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { AppToolbarProvider } from "src/components/AppToolbar/AppToolbarContext";
import { AdminProvider } from "src/context/admin-context";
import { AuthProvider } from "./auth/auth";
import { mdTheme } from "./config/theme";

export const AppProviders: React.FC = ({ children }) => {
  return (
    <React.Suspense fallback={<div>Loading</div>}>
      <ThemeProvider theme={mdTheme}>
        <CssBaseline />
        <Router>
          <AuthProvider>
            <AdminProvider>
              <AppToolbarProvider>{children}</AppToolbarProvider>
            </AdminProvider>
          </AuthProvider>
        </Router>
      </ThemeProvider>
    </React.Suspense>
  );
};
