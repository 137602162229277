import RefreshIcon from "@mui/icons-material/Refresh";
import {
  IconButton,
  ListItem,
  ListItemButton,
  ListItemText,
} from "@mui/material";
import * as React from "react";
import { useEffect, useState } from "react";
import { Flow, MailNotification } from "src/api/flow";
import { useAdmin } from "src/context/admin-context";

type FlowListItemProps = {
  flow: Flow;
  onClick?: () => void;
  onActionClick?: () => void;
};

export const FlowListItem: React.FC<FlowListItemProps> = ({
  flow,
  onClick,
  onActionClick,
}) => {
  const { admin } = useAdmin();
  const [notification, setNotification] = useState<MailNotification | null>(
    null
  );

  useEffect(() => {
    async function fetchNotification() {
      const notification = flow.mailNotification;
      setNotification(notification);
    }
    fetchNotification();
  }, [flow]);

  return (
    <ListItem
      key={flow.id}
      secondaryAction={
        admin && (
          <IconButton onClick={onActionClick} disabled={!flow.active}>
            <RefreshIcon />
          </IconButton>
        )
      }
    >
      <ListItemButton onClick={onClick} dense>
        <ListItemText
          primary={flow.name}
          secondary={notification ? notification.to.join(", ") : null}
        />
      </ListItemButton>
    </ListItem>
  );
};
