import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";
import {
  Box,
  Button,
  Card,
  CardContent,
  FormControlLabel,
  FormLabel,
  IconButton,
  MenuItem,
  Select,
  Stack,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import { SelectChangeEvent } from "@mui/material/Select/SelectInput";
import { getAuth } from "firebase/auth";
import {
  ArrayHelpers,
  FieldArray,
  Form,
  FormikProvider,
  useFormik,
} from "formik";
import * as React from "react";
import { useNavigate } from "react-router-dom";
import { createFlow, deleteFlow, Flow, updateFlow } from "src/api/flow";
import { createSite, SiteType } from "src/api/site";
import { array, object, string } from "yup";

type FlowFormProps = {
  initialValues?: FlowFormValues;
};

export type FlowFormValues = {
  flow: Flow;
};

function getInitialValues(): FlowFormValues {
  const email = getAuth().currentUser?.email;

  return {
    flow: new Flow().fromPartial({
      mailNotification: {
        to: email ? [email] : [],
        subject: "Apartments",
      },
      sites: [{ type: SiteType.HALO_OGLASI, url: "" }],
    }),
  };
}

const validationSchema = object({
  flow: object({
    mailNotification: object({
      subject: string().required(),
      to: array(string().email()).required(),
    }),
    sites: array(
      object({ type: string().required(), url: string().required() })
    ).required(),
  }),
});

export const FlowForm: React.FC<FlowFormProps> = ({ initialValues }) => {
  const navigate = useNavigate();

  const formik = useFormik<FlowFormValues>({
    initialValues: initialValues ?? getInitialValues(),
    validateOnMount: true,
    validationSchema,
    onSubmit: async ({ flow }) => {
      try {
        if (flow.id) {
          await updateFlow(flow);
        } else {
          await createFlow(flow);
        }

        navigate(-1);
      } catch (e) {
        // @ts-ignore
        alert(e.message);
      }
    },
  });

  const handleSiteChange = (
    event: SelectChangeEvent<SiteType>,
    replace: ArrayHelpers["replace"],
    index: number
  ) => {
    replace(
      index,
      createSite({
        type: event.target.value as SiteType,
      })
    );
  };

  const handleDelete = () => {
    navigate(-1);
    deleteFlow(formik.values.flow.id);
  };

  return (
    <FormikProvider value={formik}>
      <Form>
        <Stack spacing={2}>
          <Card variant="outlined">
            <CardContent>
              <Stack spacing={1}>
                <TextField
                  label="Ime"
                  variant="outlined"
                  name={`flow.name`}
                  value={formik.values.flow.name}
                  onChange={formik.handleChange}
                />
                <Box>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={formik.values.flow.active}
                        onChange={(event, checked) =>
                          formik.setFieldValue("flow.active", checked)
                        }
                      />
                    }
                    label="Aktivan"
                  />
                </Box>
              </Stack>
            </CardContent>
          </Card>

          <Card variant="outlined">
            <CardContent>
              <Typography variant={"h6"} gutterBottom component="div">
                Podešavanje email notifikacija
              </Typography>

              <FormLabel>To:</FormLabel>

              <FieldArray name={`flow.mailNotification.to`}>
                {({ push, remove }) => (
                  <Stack spacing={2} mb={2}>
                    {formik.values.flow.mailNotification.to.map(
                      (email, index) => (
                        <Stack
                          direction="row"
                          spacing={1}
                          key={index}
                          alignItems="center"
                        >
                          <TextField
                            variant="outlined"
                            name={`flow.mailNotification.to.${index}`}
                            value={email}
                            onChange={formik.handleChange}
                            fullWidth
                            type={"email"}
                          />
                          {formik.values.flow.mailNotification.to.length >
                            1 && (
                            <IconButton
                              onClick={() => remove(index)}
                              color={"error"}
                            >
                              <CloseIcon />
                            </IconButton>
                          )}
                        </Stack>
                      )
                    )}
                    <Box>
                      <Button
                        startIcon={<AddIcon />}
                        onClick={() => push("")}
                        variant={"outlined"}
                      >
                        Dodaj email
                      </Button>
                    </Box>
                  </Stack>
                )}
              </FieldArray>
              <FormLabel>Subject:</FormLabel>
              <Stack spacing={2}>
                <TextField
                  variant="outlined"
                  name={`flow.mailNotification.subject`}
                  value={formik.values.flow.mailNotification.subject}
                  onChange={formik.handleChange}
                />
              </Stack>
            </CardContent>
          </Card>

          <Card variant="outlined">
            <CardContent>
              <Typography variant={"h6"} gutterBottom component="div">
                Sajtovi za pretragu
              </Typography>

              <FieldArray name="flow.sites">
                {({ push, remove, replace }) => (
                  <Box>
                    {formik.values.flow.sites.map((site, index) => (
                      <Stack
                        key={index}
                        direction={"row"}
                        spacing={2}
                        alignItems={"center"}
                        sx={{ mb: 2 }}
                      >
                        <Stack spacing={1} sx={{ flexGrow: 1 }}>
                          <Select<SiteType>
                            label="Sajt"
                            name={`flow.sites.${index}.type`}
                            value={site.type}
                            onChange={(event) =>
                              handleSiteChange(event, replace, index)
                            }
                            variant="outlined"
                            fullWidth
                          >
                            {Object.entries(SiteType).map(([key, value]) => (
                              <MenuItem key={key} value={value}>
                                {key}
                              </MenuItem>
                            ))}
                          </Select>

                          <TextField
                            variant="outlined"
                            name={`flow.sites.${index}.url`}
                            value={site.url}
                            onChange={formik.handleChange}
                            label="URL"
                          />
                        </Stack>
                        {formik.values.flow.sites.length > 1 && (
                          <div>
                            <IconButton
                              onClick={() => remove(index)}
                              color={"error"}
                            >
                              <CloseIcon />
                            </IconButton>
                          </div>
                        )}
                      </Stack>
                    ))}

                    <Button
                      startIcon={<AddIcon />}
                      onClick={() => push(createSite())}
                      variant={"outlined"}
                    >
                      Dodaj sajt
                    </Button>
                  </Box>
                )}
              </FieldArray>
            </CardContent>
          </Card>

          <Box sx={{ alignSelf: "center" }}>
            <Button
              variant="outlined"
              onClick={() => navigate(-1)}
              sx={{ mt: 1, mr: 1 }}
            >
              Odustani
            </Button>
            <Button
              variant="contained"
              type="submit"
              disabled={!formik.isValid}
              sx={{ mt: 1, mr: 1 }}
            >
              Sačuvaj
            </Button>
          </Box>

          {formik.values.flow.id && (
            <Box sx={{ alignSelf: "center" }}>
              <Button
                variant="contained"
                color="error"
                onClick={handleDelete}
                sx={{ mt: 1, mr: 1 }}
              >
                Obriši
              </Button>
            </Box>
          )}
        </Stack>
      </Form>
    </FormikProvider>
  );
};
