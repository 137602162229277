import { collection } from "@firebase/firestore";
import { BaseModel, createConverter } from "src/api/api-factory";
import { getFlowDocRef } from "src/api/flow";
import { SiteType } from "src/api/site";

export class Advert implements BaseModel {
  id = "";
  url: string = "";
  title: string = "";
  price: number = 0;
  size: number = 0;
  img: string = "";
  description: string = "";
  siteType: SiteType = SiteType.HALO_OGLASI;
  scrapedAt: Date = new Date();

  fromPartial(data: Partial<this>): this {
    this.id = data.id ?? this.id;
    this.url = data.url ?? this.url;
    this.title = data.title ?? this.title;
    this.price = data.price ?? this.price;
    this.img = data.img ?? this.img;
    this.size = data.size ?? this.size;
    this.description = data.description ?? this.description;
    this.siteType = data.siteType ?? this.siteType;
    // @ts-ignore
    this.scrapedAt = data.scrapedAt?.toDate?.() ?? this.scrapedAt;
    return this;
  }
}

export const converter = createConverter(Advert);

export const getAdvertsCollectionRef = (flowId: string) =>
  collection(getFlowDocRef(flowId), "adverts").withConverter(converter);
