import { Button, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import * as React from "react";

type EmptyViewProps = {
  text?: string;
  onCreateBtnClick?: () => void;
};

export const EmptyView: React.FC<EmptyViewProps> = ({
  text = "No data",
  onCreateBtnClick,
}) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Typography gutterBottom>{text}</Typography>
      {onCreateBtnClick && (
        <Button onClick={onCreateBtnClick} variant={"outlined"}>
          Napravi
        </Button>
      )}
    </Box>
  );
};
