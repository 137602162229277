import { QueryConstraint } from "@firebase/firestore";
import { CollectionReference, onSnapshot, query } from "firebase/firestore";
import { useEffect, useRef, useState } from "react";

export const useCollectionSubscription = <T>(
  getCollectionRef: () => CollectionReference<T>,
  queryConstraints: QueryConstraint[] = []
) => {
  const [data, setData] = useState<T[]>([]);
  const [loading, setLoading] = useState(true);

  const innerCollectionRef = useRef(getCollectionRef);
  const innerQueryConstraints = useRef(queryConstraints);

  useEffect(() => {
    const ref = innerCollectionRef.current();
    const q = query<T>(ref, ...innerQueryConstraints.current);
    const unsubscribe = onSnapshot(
      q,
      (querySnapshot) => {
        setLoading(false);
        const scrapers: T[] = [];
        querySnapshot.forEach((doc) => {
          scrapers.push(doc.data());
        });
        setData(scrapers);
      },
      (error) => {
        console.log(error);
      }
    );

    return () => {
      unsubscribe();
    };
  }, []);

  return { data, loading };
};
